import axios from 'axios';
import React, { MouseEvent } from 'react';
import { Button, Card, Image } from 'react-bootstrap';
import { WithTranslation, withTranslation } from "react-i18next";
import { FaCamera, FaUpload } from 'react-icons/fa';
import Resizer from 'react-image-file-resizer';
import { compress, EImageType } from 'image-conversion';

/*const FileBox: React.FC<{}> = (props: {}) => {
    //export class Uploader extends React.Component<UploaderProps, UploaderState> {
    const { t, i18n } = useTranslation();

    return <div id="test" onClick={this._handleClick}>
        <input ref="fileField" type="file" name="image1" accept=".jpg,.jpeg,.png" id="img1" />
        <div id="uploadPhotoButtonText">
            +Add Photo 1
    </div>
    </div>;
}*/
interface FileBoxProps extends WithTranslation { step: number, code: string, finishStep: CallableFunction, nextStep: CallableFunction, lastUpload?: number }
interface FileBoxState { preview: string, raw?: File, fileprogress: number, lastUpload?: number }
interface ArcServerResponse { status: number, reason: string }
/**
 * Class to display a box to select an image, show a preview and upload the image
 */
class FileBox extends React.Component<FileBoxProps, FileBoxState> {
    private stepInput: React.RefObject<HTMLInputElement>;
    private stepFinish: React.RefObject<HTMLDivElement>;
    /**
     * Constructur for the FileBox
     * @param props props of the Component
     */
    constructor(props: FileBoxProps) {
        super(props);
        this.stepInput = React.createRef();
        this.stepFinish = React.createRef();
        this.state = { preview: '', raw: undefined, fileprogress: -1 }
    }
    /**
     * Function to refer a click action to the hidden input element
     */
    selectImage() {
        if (this.stepInput.current != null) {
            this.stepInput.current.click();
        }
    }
    /**
     * Function to convert a Blob to File type
     * @param theBlob 
     * @param fileName 
     */
    convertToFile(blob: Blob, fileName: string) {
        var b: any = blob;
        b.lastModifiedDate = new Date('28-06-2020');
        b.name = fileName;
        //Cast to a File type
        return new File([blob], fileName, { type: 'image/jpeg' });
    }

    /**
     * Function to handle the suggested Image, compress it, show a preview and prepare the upload
     * @param e ChangeEvent of the input field
     */
    handleChange(e: React.ChangeEvent<HTMLInputElement>) {//React.FormEvent<HTMLInputElement> oder React.ChangeEvent<HTMLInputElement>
        if (e.target.files != null && e.target.files.length > 0) {
            try {
                //calculate image size and compress the image
                let fileSize = Math.floor(e.target.files[0].size / 1024 / 1024 * 10) / 10;
                if (fileSize > 1) {
                    compress(e.target.files[0], {
                        quality: fileSize > 2 ? 0.8 : 0.9,
                        type: EImageType.JPEG,
                        //width: 300,
                        //height: 200,
                        //orientation: 2,
                        scale: fileSize > 2 ? 0.5 : 0.75,
                    }).then(res => {
                        //save the compressed file
                        let img = this.convertToFile(res, "arc-upload.jpg");
                        this.setState({
                            preview: URL.createObjectURL(img),
                            raw: img
                        });
                    });
                } else {
                    //just use the uncompressed file
                    this.setState({
                        preview: URL.createObjectURL(e.target.files[0]),
                        raw: e.target.files[0]
                    });
                }
            } catch (error) {
                //just use the uncompressed file
                this.setState({
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0]
                });
            }
        }
    }

    /**
     * Function to handle the Upload
     * @param e ClickEvent of the Upload Button
     */
    async handleUpload(e: MouseEvent) {
        e.preventDefault();
        if (this.state.raw != undefined) {
            this.setState({ fileprogress: 0 });
            const formData = new FormData();
            formData.append("image", this.state.raw);
            axios.request({
                method: 'post',
                url: 'upload.php?step=' + this.props.step + '&code=' + this.props.code,
                //'http://localhost/arc-unloader/upload.php?step=' + this.props.step + '&code=' + this.props.code,
                data: formData,
                onUploadProgress: (p: ProgressEvent) => {
                    let progress = p.loaded / p.total;
                    this.setState({
                        fileprogress: progress > 0.95 ? 0.95 : progress
                    })
                }
            }).then(response => {
                this.setState({
                    fileprogress: -1,
                })
                if (response.data != '1') {
                    //response.data
                    try {
                        alert(this.props.t('upload.error-' + response.data['reason']));
                    } catch (e) {
                        alert(this.props.t('upload.error'));
                    }
                    //throw new TypeError("wrong status"); 
                } else {
                    this.finishThisStep();
                }
            })
        }
    }

    /**
     * Function to finish the current Upload-Step and set the values in the parent component.
     */
    finishThisStep() {
        if (this.props.step < 4) {
            //activate next step
            this.props.finishStep(2);
            this.props.nextStep(1);
        } else if (this.props.lastUpload != undefined) {
            //in the 4th step just increase the number of uploaded images
            console.log('update lastUpload:' + this.props.lastUpload);
            this.props.finishStep(this.props.lastUpload+1);
            this.props.nextStep(this.props.lastUpload + 2);
        }
    }

    render() {
        // This syntax ensures `this` is bound within handleClick
        return (
            <Card>
                {this.props.step < 4 && <Card.Header>{this.props.t('upload.step' + this.props.step + '-description')}</Card.Header>}
                <div className="doku-upload" onClick={() => this.selectImage()}>
                    <div>
                        {this.state.preview ?
                            (<Image src={this.state.preview} />) :
                            (<>
                                <FaCamera />
                                <br /><br />
                                <Button>{this.props.t('upload.take-photo')}</Button>
                            </>)}
                        <input ref={this.stepInput} type="file" accept="image/*" capture="environment" onChange={(e) => this.handleChange(e)} />
                    </div>
                </div>
                {this.state.fileprogress >= 0 && <div className="progress overlap-button">
                    <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: Math.ceil(this.state.fileprogress * 100) + '%' }}>{Math.ceil(this.state.fileprogress * 100) + '%'}</div></div>}
                {(this.state.preview && this.state.fileprogress < 0) &&
                    <div ref={this.stepFinish} onClick={(e: MouseEvent) => this.handleUpload(e)} className="btn btn-primary overlap-button"><FaUpload /> {this.props.t('upload.start-upload')}</div>}
            </Card>
        );
    }
}

export default withTranslation()(FileBox);