import React from 'react';
import { NavDropdown, Navbar, Nav, Button, Form, Dropdown } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
//import { useTranslation } from 'react-i18next';
import { withTranslation, WithTranslation } from 'react-i18next';


class AppBar extends React.Component<WithTranslation> {

    render() {
        //const { t } = useTranslation();
        const changeLanguage = (language: string) => this.props.i18n.changeLanguage(language);
        return <Navbar bg="primary" expand="lg">
            <LinkContainer to={'/'}>
                <img src="arc-logo.png" className="app-logo" />
            </LinkContainer>
            {/*<LinkContainer to={'/'}>
                <Navbar.Brand>{this.props.t('navbar.brand')}</Navbar.Brand>
    </LinkContainer>*/}


            <Navbar.Toggle aria-controls="basic-navbar-nav" className="float-right" />
            <Navbar.Collapse id="basic-navbar-nav" className="float-right">
                <Nav className="mr-auto">
                    <LinkContainer to={'/'}>
                        <Nav.Link>{this.props.t('navbar.start')}</Nav.Link>
                    </LinkContainer>

                    <a className="nav-link" href="https://construction.arcelormittal.com/de-de/impressum">{this.props.t('navbar.imprint')}</a>
                    <LinkContainer to={'/privacy'}><Nav.Link>{this.props.t('navbar.privacy')}</Nav.Link></LinkContainer>
                    {/*
                            <LinkContainer to={'/imprint'}><Nav.Link>{this.props.t('navbar.imprint')}</Nav.Link></LinkContainer>
                            <NavDropdown title={this.props.t('navbar.language')} id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={() => changeLanguage("en")}>{this.props.t('navbar.english')}</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => changeLanguage("de")}>{this.props.t('navbar.german')}</NavDropdown.Item>
</NavDropdown>*/}
                </Nav>
            </Navbar.Collapse>
            <Dropdown>
                <Dropdown.Toggle variant="secondary" id="language-dropdown">
                    {this.props.t('navbar.language')}
                </Dropdown.Toggle>

                <Dropdown.Menu className="right-lg-box">
                    <Dropdown.Item onClick={() => changeLanguage("de")} className={(this.props.t('navbar.code') == "de" ? "active" : "")}>Deutsch</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeLanguage("en")} className={(this.props.t('navbar.code') == "en" ? "active" : "")}>English</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeLanguage("ro")} className={(this.props.t('navbar.code') == "ro" ? "active" : "")}>Română</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeLanguage("hu")} className={(this.props.t('navbar.code') == "hu" ? "active" : "")}>Magyar</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeLanguage("pl")} className={(this.props.t('navbar.code') == "pl" ? "active" : "")}>Polski</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeLanguage("sk")} className={(this.props.t('navbar.code') == "sk" ? "active" : "")}>Slovenský</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeLanguage("cz")} className={(this.props.t('navbar.code') == "cz" ? "active" : "")}>Čeština</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {/*
                <div className="language-block">
                
                    <Button onClick={() => changeLanguage("en")} className="float-right">{this.props.t('navbar.english')}</Button>
                    <Button onClick={() => changeLanguage("de")} className="float-right">{this.props.t('navbar.german')}</Button>
                </div>*/}
        </Navbar>;
    }
}

export default withTranslation()(AppBar);