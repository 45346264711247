import React from 'react';
import { Container, Jumbotron } from 'react-bootstrap';
import AppBar from '../Components/AppBar';
import { useTranslation } from 'react-i18next';

const Danke:React.FC<{}> = (props:{}) => {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <AppBar />
            <Container fluid="sm">
                <Jumbotron>
                    <h1>{t('thanks.title')}</h1>

                </Jumbotron>
                <p>
                    {t('thanks.description')}
                </p>
            </Container>
        </div>
    );
}
export default Danke;