import axios from 'axios';
import React, { createRef, MouseEvent, useState, ChangeEvent } from 'react';
import { Button, Card, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { FaCheck, FaCheckCircle } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import FileBox from './FileBox';
import NavbarCollapse from 'react-bootstrap/NavbarCollapse';

type UploaderProps = {
    code: string
}
type UploaderState = {
    uploadState: number
}


const Uploader: React.FC<UploaderProps> = (props: UploaderProps) => {
    //export class Uploader extends React.Component<UploaderProps, UploaderState> {
    /// to control the route
    const history = useHistory();
    /// translation
    const { t, i18n } = useTranslation();
    /// number to check state of the step. 0: hidden; 1: active; 2: done
    const [step1, setStep1] = useState(1);
    /// number to check state of the step. 0: hidden; 1: active; 2: done
    const [step2, setStep2] = useState(0);
    /// number to check state of the step. 0: hidden; 1: active; 2: done
    const [step3, setStep3] = useState(0);
    /// number to check state of the step. 0: hidden; 1: active; 2: done
    const [step4, setStep4] = useState(0);
    /// number to check state whether a damage happened to activate the additional content
    const [damage, setDamage] = useState(0);
    /// bool to save when the damage was created
    const [damageBefore, setDamageBefore] = useState(false);
    /// bool to save when the damage was created
    const [damageDuring, setDamageDuring] = useState(false);
    /// bool to save when the damage was created
    const [damageAfter, setDamageAfter] = useState(false);
    /// status of the comment-textarea. 0:empty&not changed; 1:changed - should be saved; 2: saved & not changed
    const [saveText, setSaveText] = useState(0);
    const [errorText, setErrorText] = useState('');
    /// number of additional uploaded images in step4
    const [addUploads, setAddUploads] = useState(0);
    /// last added upload in step 3
    const [lastAddUploads, setLastAddUploads] = useState(-1);


    /// reference to control the textarea
    const commentTextArea = createRef<HTMLTextAreaElement>();

    //Create Upload Icons
    let uploads = [];
    for (let i = 0; i < addUploads; i++) {
        uploads.push(<FaCheckCircle className="text-success" />);
    }


    return (<div>
        {/*<Jumbotron>
            <h1>{t('home.title')}</h1>*/}
        <br />
        <p>
            {t('home.description')}
        </p>
        <p className="text-center">
            {t('home.given-code')} {props.code}
        </p>
        {/*</Jumbotron>*/}

        {/*<Card.Title>{t('upload.step1-title')}</Card.Title>*/}

        {step1 == 1 && (<FileBox step={1} code={props.code} finishStep={setStep1} nextStep={setStep2} />)}
        {step1 == 2 && (
            <Card>
                <Card.Header>{t('upload.step1-short')}<FaCheckCircle className="text-success float-right" /></Card.Header>
            </Card>
        )}
        <br />
        {step2 == 0 && (
            <Card>
                <Card.Header>{t('upload.step2-short')}</Card.Header>
            </Card>
        )}
        {step2 == 1 && (<FileBox step={2} code={props.code} finishStep={setStep2} nextStep={setStep3} />)}
        {step2 == 2 && (
            <Card>
                <Card.Header>{t('upload.step2-short')}<FaCheckCircle className="text-success float-right" /></Card.Header>
            </Card>
        )}
        <br />
        {step3 == 0 && (
            <Card>
                <Card.Header>{t('upload.step3-short')}</Card.Header>
            </Card>
        )}
        {step3 == 1 && (<FileBox step={3} code={props.code} finishStep={setStep3} nextStep={setStep4} />)}
        {step3 == 2 && (
            <Card>
                <Card.Header>{t('upload.step3-short')}<FaCheckCircle className="text-success float-right" /></Card.Header>
            </Card>
        )}
        <br />
        {(step4 == 3 || step4 == 0) && (
            <Card>
                <Card.Header>{t('upload.step4-short')}</Card.Header>
            </Card>
        )}
        {step4 == 1 && (<>
            <Card>
                <Card.Header>{t('upload.step4-description')}<span className="badge badge-danger"></span></Card.Header>
                {damage == 0 && (
                    <>
                        <Button size="lg" variant={"success"} onClick={() => {
                            //setDamage(-1);
                            history.push("/thanks");
                        }}>
                            {t('upload.damage-no')}
                        </Button> {' '}
                        <Button size="lg" variant={"danger"} onClick={() => {
                            setDamage(1)
                        }}>
                            {t('upload.damage-yes')}
                        </Button>
                    </>)}
                {damage == 1 && (
                    <Card.Body>
                        <div className="col-sm-6">
                            <Card.Title>{t('upload.step4-question')}</Card.Title>
                            <div key={`cbDamage`} className="mb-3">
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id={`cbDamage1`}
                                    label={t('upload.damage-before-unloading')}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setDamageBefore(e.target.checked); setSaveText(1); }}
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id={`cbDamage2`}
                                    label={t('upload.damage-during-unloading')}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setDamageDuring(e.target.checked); setSaveText(1); }}
                                />
                                <Form.Check
                                    custom
                                    type='checkbox'
                                    id={`cbDamage3`}
                                    label={t('upload.damage-after-unloading')}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setDamageAfter(e.target.checked); setSaveText(1); }}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <br />
                            <Form.Label>{t('upload.add-comment')}</Form.Label>
                            <Form.Control as="textarea" rows={3} ref={commentTextArea} onKeyUp={() => { setSaveText(1); setErrorText(''); }} />
                            {errorText.length > 0 && <div className="alert alert-danger float-left">{errorText}</div>}
                            <div onClick={(e: MouseEvent) => {
                                //todo: find better place for this function
                                e.preventDefault();
                                if (commentTextArea.current != undefined && commentTextArea.current.value != undefined && (commentTextArea.current.value.length > 0 || damageBefore || damageDuring || damageAfter)) {
                                    //Update comment text on server
                                    //console.log(commentTextArea.current.textContent.length)
                                    var textContent: string = '' + t('upload.step4-question');
                                    if (damageBefore) { textContent += ' (' + t('damage-before-unloading') + ')' }
                                    if (damageDuring) { textContent += ' (' + t('damage-during-unloading') + ')' }
                                    if (damageAfter) { textContent += ' (' + t('damage-after-unloading') + ')' }
                                    textContent += '\r\n' + commentTextArea.current.value;
                                    axios.post('upload.php?step=3&code=' + props.code,
                                        //'http://localhost/arc-unloader/upload.php?step=3&code=' + props.code,
                                     { text: textContent })
                                    .then((res) => {
                                        if (res.data != '1') {
                                            setErrorText(t('upload.error-comment'));
                                        } else {
                                            setErrorText('');
                                            setSaveText(2);
                                        }
                                    }).catch((error) => {
                                        console.log(error);
                                        setErrorText('');
                                        setSaveText(2);
                                    });

                                }
                            }} className={"float-right btn btn-" + (saveText != 1 ? 'secondary' : 'primary')}>{saveText < 2 && t('upload.save')}{saveText == 2 && <>{t('upload.saved')} <FaCheck /></>}</div>
                            <div className="clearfix"></div>
                            <br />
                            <Card.Title>{t('upload.additional-picture')}</Card.Title>

                            {addUploads > 0 && <Card><Card.Header>{t('upload.additional-uploads')} {uploads}</Card.Header></Card>}
                            <FileBox key={'filebox-' + lastAddUploads} step={4} lastUpload={lastAddUploads} code={props.code} finishStep={setLastAddUploads} nextStep={setAddUploads} />
                            <br />
                            <br />
                            <div className="btn btn-success" onClick={() => { history.push("/thanks"); }}>{t('upload.finish-process')}</div>
                        </div>

                    </Card.Body>
                )}
            </Card>
        </>)}
    </div>);
}

export default Uploader;