import React, { ComponentProps, useState } from 'react';
import { Button, Container, Jumbotron } from 'react-bootstrap';
import AppBar from '../Components/AppBar';
import Uploader from '../Components/Uploader';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//interface IComponentProps extends WithTranslation {}
const Home: React.FC<{}> = (props: {}) => {
    const { t, i18n } = useTranslation();
    //const [active, setActive] = useState(false);
    let query = new URLSearchParams(useLocation().search);
    let code = query.get('code') + '';
    let active = code.length > 5;
    /*class Home extends React.Component<IComponentProps,{active:boolean, code:string}> {
        constructor(props:IComponentProps){
            super(props);
            let query = new URLSearchParams(useLocation().search);
            let code = query.get('code');
            this.state = {active:this.checkCode(code), code: code + ''};
        }
        checkCode(code: string | null): boolean {
            return code != null && code.length > 0;
        }
        render() {*/
    let content = active ? <Uploader code={code} /> : <Jumbotron>
        <h1>{t('home.title')}</h1>
        <br /><br />
        <p className="alert alert-danger">
            {t('home.no-code')}
        </p>
    </Jumbotron>;
    return (
        <div>
            <AppBar />
            <Container fluid="sm">
                {content}
            </Container>
        </div>
    );
};
export default Home;