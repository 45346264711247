import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';
import translationSK from './locales/sk/translation.json';
import translationRO from './locales/ro/translation.json';
import translationPOL from './locales/pol/translation.json';
import translationCZ from './locales/cz/translation.json';
import translationHU from './locales/hu/translation.json';
// the translations
// (tip move them in a JSON file and import them)
/*const resources = {
  en: {
    translation: {
      "navbar-brand": "Unloading Documentation",
      "Invalid login or password, please type again":
        "Invalid login or password, please type again",
      "error, review the fields": "error, review the fields",
    },
  },
  de: {
    translation: {
      "navbar-brand": "Entlade-Dokumentation",
      "Invalid login or password, please type again":
        "Usuario o clave no validos, porfavor intentelo de nuevo",
      "error, review the fields": "Error, revise los campos por favor",
    },
  },
};*/

// the translations
const resources = {
    en: {
      translation: translationEN
    },
    de: {
      translation: translationDE
    },
    cz: {
      translation: translationCZ
    },
    ro: {
      translation: translationRO
    },
    pl: {
      translation: translationPOL
    },
    hu: {
      translation: translationHU
    },
    sk: {
      translation: translationSK
    },
  };

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    //keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
