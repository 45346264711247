import React from 'react';
import { Container, Jumbotron } from 'react-bootstrap';
import AppBar from '../Components/AppBar';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const Datenschutz:React.FC<{}> = (props:{}) => {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <AppBar />
            <Container fluid="sm">
                <Jumbotron>
                    <h1>{t('privacy.title')}</h1>

                </Jumbotron>
                <p>
                    <Trans i18nKey="privacy.description">
                        Klicken Sie <a href="https://construction.arcelormittal.com/de-de/privatsphare">hier</a>.
                    </Trans>
                </p>
            </Container>
        </div>
    );
}
export default Datenschutz;