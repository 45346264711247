import React from 'react';
import { Route } from "react-router-dom";

import './App.css';
import Home from './Routes/Home';
import Impressum from './Routes/Impressum';
import Datenschutz from './Routes/Datenschutz';
import Danke from './Routes/Danke';

export const App: React.StatelessComponent = () => {
  
  return <div>
    <Route exact={true} path="/" component={Home} />
    {/*<Route path="/imprint" component={Impressum} />*/}
    <Route path="/privacy" component={Datenschutz} />
    <Route path="/thanks" component={Danke} />
  </div>
}

export default App;



